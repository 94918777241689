import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"
import { useDispatch } from "react-redux";

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import OneClickMadridNotifier from "../../pages/MadridNotifier/OneClickMadridNotifier"

import {
  setOcmnShowStartup,
} from "../../store/actions";

const SidebarContent = props => {
  const dispatch = useDispatch();
  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  },[]);  

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

  }, [props.location.pathname,activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const goLink = url => {
    window.location.href=url;
  }

  return (
    <React.Fragment>
      
      <SimpleBar style={{ maxHeight: "100%", width: "100%" }} ref={ref} className="sidebar-menu-scroll" >
        <div id="sidebar-menu" >
          <ul className="metismenu list-unstyled" id="side-menu">
            {
            JSON.parse(localStorage.getItem("authUser")).adminYn == 'Y' && JSON.parse(localStorage.getItem("authUser")).virtualLogIn == 'N'?
            <>
              <li>
                <Link to="/admMyHome" className="waves-effect" onClick={() => goLink('/admMyHome')}>
                  <i className="uil-apps"></i>
                  <span>{props.t("My Page Home")}</span>
                </Link>
              </li>

              <li className="menu-title">{props.t("Apps")}</li>

              <li>
                <Link to="/admAccountManagement" className=" waves-effect" onClick={() => goLink('/admAccountManagement')}>
                  <i className="uil-user"></i>
                  <span >{props.t("Account Management")}</span>
                </Link>
              </li>
              
              {/*
              <li>
                <Link to="/admInvoiceManagement_tab" className=" waves-effect" onClick={() => goLink('/admInvoiceManagement_tab')}>
                  <i className="uil-invoice"></i>
                  <span>{props.t("Invoice Management")}</span>
                </Link>
              </li>
              */}

              <li>
                <Link to="/admInvoiceManagement" className=" waves-effect">
                  <i className="uil-invoice"></i>
                  <span>{props.t("Invoice Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/admPricingManagement" className=" waves-effect">
                  <i className="uil-dollar-alt"></i>
                  <span>{props.t("Pricing Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/admContacts" className=" waves-effect" onClick={() => goLink('/admContacts')}>
                  <i className="uil-at"></i>
                  <span>{props.t("Contacts management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/admDomainManagement" className=" waves-effect">
                  <i className="uil-globe"></i>
                  <span>{props.t("Domain Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/admServiceManual" className=" waves-effect">
                  <i className="uil-book-open"></i>
                  <span>{props.t("Manual Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/admEntityManagement" className=" waves-effect">
                  <i className="uil-object-group"></i>
                  <span>{props.t("Entity Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/admMyCaseManagement" className=" waves-effect">
                  <i className="uil-layer-group"></i>
                  <span>{props.t("My Case Management")}</span>
                </Link>
              </li>
              
              <li>
                <Link to="/admSettings" className=" waves-effect" onClick={() => goLink('/admSettings')}>
                  <i className="uil-cog"></i>
                  <span>{props.t("Settings")}</span>
                </Link>
              </li>

              <li>
                <Link to="/logout" className=" waves-effect">
                  <i className="uil-sign-out-alt"></i>
                  <span>{props.t("Logout")}</span>
                </Link>
              </li>

            </>
            :
            <>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="uil-apps"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Apps")}</li>

            <li>
              <Link to="/madridNotifier" className=" waves-effect">
                <i className="uil-envelopes"></i>
                <span>{props.t("Madrid Notifier")}</span>
              </Link>
            </li>

            <li>
              <Link to="/myCaseManagement" className=" waves-effect">
                <i className="uil-layer-group"></i>
                <span>{props.t("My Case Management")}</span>
              </Link>
            </li>
            
            <li>
              
              <Link to="/#" className=" waves-effect" 
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setOcmnShowStartup('Y'));
                    }}>
                <i className="mdi mdi-cursor-default-click-outline"></i>
                <span>{props.t("OCMN")}</span>
              </Link>
            </li>
            {/*
            <li style={{padding: "0.6rem 1.1rem", margin: "0 10px"}}>
                <button
                  type="button"
                  onClick={() => {
                    dispatch(setOcmnShowStartup(true));
                  }}
                  className="btn btn-primary btn-ocmn waves-effect"
                  style={{width: "150px", padding: "3px",}}
                >
                  OCMN
                </button>
            </li>
            */}

            <li>
              <Link to="/filerRankings" className=" waves-effect">
                <i className="uil-medal"></i>
                <span>{props.t("Filer Rankings")}</span>
              </Link>
            </li>

            <li>
              <Link to="/generalStatistics" className=" waves-effect">
                <i className="uil-comparison"></i>
                <span >{props.t("General Statistics")}</span>
              </Link>
            </li>
            
            <li>
              <Link to="/countryRankings" className=" waves-effect">
                <i className="uil-globe"></i>
                <span>{props.t("Country Rankings")}</span>
              </Link>
            </li>
            
           
            <li>
              <Link to="/specificSearch" className=" waves-effect">
                <i className="uil-crosshair"></i>
                <span>{props.t("Specific Search")}</span>
              </Link>
            </li>
           
            <li>
              <Link to="/contacts" className=" waves-effect">
                <i className="uil-at"></i>
                <span>{props.t("Contacts")}</span>
              </Link>
            </li>

            <li>
              <Link to="/entityConnect" className=" waves-effect">
                <i className="uil-object-group"></i>
                <span>{props.t("Entity Connect")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-envelope-info"></i>
                <span>{props.t("Sending Analytics")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/madridNotifierSendingAnalytics">{props.t("Madrid Notifier")}</Link>
                </li>
                <li>
                  <Link to="/filerRankingsSendingAnalytics">{props.t("Filer Rankings")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-cog"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/membershipPlan">{props.t("Membership plan")}</Link>
                </li>
                <li>
                  <Link to="/invoiceSetting">{props.t("Invoicing")}</Link>
                </li>
                <li>
                  <Link to="/accountSettings">{props.t("Account")}</Link>
                </li>
                <li>
                  <Link to="/emailingSettings">{props.t("Emailing")}</Link>
                </li>
              </ul>
            </li>
            <li>
                <Link to="/wecrestManual" className=" waves-effect">
                  <i className="uil-book-open"></i>
                  <span>{props.t("WeCrest Manual")}</span>
                </Link>
            </li>
            <li>
              <Link to="/membershipPlan" className=" waves-effect">
                <i className="mdi mdi-arrow-up-bold-hexagon-outline"></i>
                <span>{props.t("Upgrade plan")}</span>

                {/*
                <button
                  type="button"
                  onClick={() => {
                    window.location.href = "/membershipPlan";
                  }}
                  className="btn btn-primary btn-wcdark waves-effect"
                  style={{width: "150px", padding: "3px"}}
                >
                  Upgrade plan
                </button>
                */}
              </Link>
            </li>
            {/*
            <li>
                <Link to="/community" className=" waves-effect">
                  <i className="uil-users-alt"></i>
                  <span>{props.t("Community")}</span>
                </Link>
            </li>
            */}
            </>
            }
          </ul>
        </div>
      </SimpleBar>

      <OneClickMadridNotifier/>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))