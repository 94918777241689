import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// MadridNotifier Redux States
import { 
  GET_SERVICE_MANUAL_LIST,
  GET_SERVICE_MANUAL_INFO,
  UPDATE_SERVICE_MANUAL,
  UPDATE_SERVICE_MANUAL_VIEW_CNT,
  UPDATE_SERVICE_MANUAL_PRIORITY,
  UPDATE_SERVICE_MANUAL_PDF,
  GET_PREVIEW_SERVICE_MANUAL,
} from "./actionTypes"
import { 
  getServiceManualListSuccess,
  getServiceManualInfoSuccess,
  updateServiceManualSuccess,
  updateServiceManualViewCntSuccess,
  updateServiceManualPrioritySuccess,
  updateServiceManualPDFSuccess,
  getPreviewServiceManualSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getServiceManualList,
  getServiceManualInfo,
  updateServiceManual,
  updateServiceManualViewCnt,
  updateServiceManualPriority,
  updateServiceManualPDF,
  previewServiceManual,
} from "../../helpers/backend_helper"

function* onGetServiceManualList({ payload : data }) {
  try {
    const response = yield call(getServiceManualList, data);
    yield put(getServiceManualListSuccess(response));
    
  } catch (error) {
    console.log('onGetServiceManualList error >> ' + error);
  }
}

function* onGetServiceManualInfo({ payload : data }) {
  try {
    const response = yield call(getServiceManualInfo, data);
    yield put(getServiceManualInfoSuccess(response));
    
  } catch (error) {
    console.log('onGetServiceManualInfo error >> ' + error);
  }
}

function* onUpdateServiceManual({ payload : data }) {
  try {
    let crudType = '';
    data.formData.forEach((value, key) => {
      if (key == 'crudType') {
        crudType = value;
      }
    });
    console.log('data.formData.crudType : ' + crudType);
    const response = yield call(updateServiceManual, data.formData);
    yield put(updateServiceManualSuccess(response, crudType, data.serviceManualInfo));
    
  } catch (error) {
    console.log('onUpdateServiceManual error >> ' + error);
  }
}

function* onUpdateServiceManualViewCnt({ payload : data }) {
  try {
    const response = yield call(updateServiceManualViewCnt, data);
    yield put(updateServiceManualViewCntSuccess(data));
    
  } catch (error) {
    console.log('onUpdateServiceManualViewCnt error >> ' + error);
  }
}

function* onUpdateServiceManualPriority({ payload : data }) {
  try {
    const response = yield call(updateServiceManualPriority, data.data);
    yield put(updateServiceManualPrioritySuccess(response, data.isReload));
    
  } catch (error) {
    console.log('onUpdateServiceManualPriority error >> ' + error);
  }
}

function* onUpdateServiceManualPDF({ payload : data }) {
  try {
    const response = yield call(updateServiceManualPDF, data);
    yield put(updateServiceManualPDFSuccess(response));
    
  } catch (error) {
    console.log('onUpdateServiceManualPDF error >> ' + error);
  }
}

function* onGetPreviewServiceManual({ payload : data }) {
  try {
    const response = yield call(previewServiceManual, data);
    yield put(getPreviewServiceManualSuccess(response));
    
  } catch (error) {
    console.log('onPreviewServiceManual error >> ' + error);
  }
}

function* ServiceManualSaga() {
  yield takeLatest(GET_SERVICE_MANUAL_LIST, onGetServiceManualList);
  yield takeLatest(GET_SERVICE_MANUAL_INFO, onGetServiceManualInfo);
  yield takeLatest(UPDATE_SERVICE_MANUAL, onUpdateServiceManual);
  yield takeLatest(UPDATE_SERVICE_MANUAL_VIEW_CNT, onUpdateServiceManualViewCnt);
  yield takeLatest(UPDATE_SERVICE_MANUAL_PRIORITY, onUpdateServiceManualPriority);
  yield takeLatest(UPDATE_SERVICE_MANUAL_PDF, onUpdateServiceManualPDF);
  yield takeLatest(GET_PREVIEW_SERVICE_MANUAL, onGetPreviewServiceManual);
}

export default ServiceManualSaga

