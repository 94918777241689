import {
  GET_SERVICE_MANUAL_LIST,
  GET_SERVICE_MANUAL_LIST_SUCCESS,
  GET_SERVICE_MANUAL_INFO,
  GET_SERVICE_MANUAL_INFO_SUCCESS,
  UPDATE_SERVICE_MANUAL,
  UPDATE_SERVICE_MANUAL_SUCCESS,
  UPDATE_SERVICE_MANUAL_VIEW_CNT,
  UPDATE_SERVICE_MANUAL_VIEW_CNT_SUCCESS,
  UPDATE_SERVICE_MANUAL_PRIORITY,
  UPDATE_SERVICE_MANUAL_PRIORITY_SUCCESS,
  UPDATE_SERVICE_MANUAL_PDF,
  UPDATE_SERVICE_MANUAL_PDF_SUCCESS,
  CHANGE_SERVICE_MANUAL_LIST,
  CHANGE_SERVICE_MANUAL_INFO,
  CHANGE_SERVICE_MANUAL_RESULT,
  CHANGE_SERVICE_MANUAL_LOADING,
  CHANGE_IS_RELOAD,
  GET_PREVIEW_SERVICE_MANUAL,
  GET_PREVIEW_SERVICE_MANUAL_SUCCESS,
  SET_PREVIEW_SERVICE_MANUAL,
} from "./actionTypes"

export const getServiceManualList = data => {
  return {
    type: GET_SERVICE_MANUAL_LIST,
    payload: data,
  }
}

export const getServiceManualListSuccess = data => {
  return {
    type: GET_SERVICE_MANUAL_LIST_SUCCESS,
    payload: data,
  }
}

export const getServiceManualInfo = data => {
  return {
    type: GET_SERVICE_MANUAL_INFO,
    payload: data,
  }
}

export const getServiceManualInfoSuccess = data => {
  return {
    type: GET_SERVICE_MANUAL_INFO_SUCCESS,
    payload: data,
  }
}

export const updateServiceManual = (formData, serviceManualInfo) => {
  return {
    type: UPDATE_SERVICE_MANUAL,
    payload: {formData, serviceManualInfo},
  }
}

export const updateServiceManualSuccess = (data, crudType, serviceManualInfo) => {
  return {
    type: UPDATE_SERVICE_MANUAL_SUCCESS,
    payload: {data, crudType, serviceManualInfo},
  }
}

export const updateServiceManualViewCnt = data => {
  return {
    type: UPDATE_SERVICE_MANUAL_VIEW_CNT,
    payload: data,
  }
}

export const updateServiceManualViewCntSuccess = data => {
  return {
    type: UPDATE_SERVICE_MANUAL_VIEW_CNT_SUCCESS,
    payload: data,
  }
}

export const updateServiceManualPriority = (data, isReload) => {
  return {
    type: UPDATE_SERVICE_MANUAL_PRIORITY,
    payload: {data, isReload},
  }
}

export const updateServiceManualPrioritySuccess = (data, isReload) => {
  return {
    type: UPDATE_SERVICE_MANUAL_PRIORITY_SUCCESS,
    payload: {data, isReload},
  }
}

export const updateServiceManualPDF = data => {
  return {
    type: UPDATE_SERVICE_MANUAL_PDF,
    payload: data,
  }
}

export const updateServiceManualPDFSuccess = data => {
  return {
    type: UPDATE_SERVICE_MANUAL_PDF_SUCCESS,
    payload: data,
  }
}

export const changeServiceManualList = data => {
  return {
    type: CHANGE_SERVICE_MANUAL_LIST,
    payload: data,
  }
}

export const changeServiceManualInfo = data => {
  return {
    type: CHANGE_SERVICE_MANUAL_INFO,
    payload: data,
  }
}

export const changeServiceManualResult = data => {
  return {
    type: CHANGE_SERVICE_MANUAL_RESULT,
    payload: data,
  }
}

export const changeServiceManualLoading = data => {
  return {
    type: CHANGE_SERVICE_MANUAL_LOADING,
    payload: data,
  }
}

export const changeIsReload = data => {
  return {
    type: CHANGE_IS_RELOAD,
    payload: data,
  }
}

export const getPreviewServiceManual = data => {
  return {
    type: GET_PREVIEW_SERVICE_MANUAL,
    payload: data,
  }
}

export const getPreviewServiceManualSuccess = data => {
  return {
    type: GET_PREVIEW_SERVICE_MANUAL_SUCCESS,
    payload: data,
  }
}

export const setPreviewServiceManual = data => {
  return {
    type: SET_PREVIEW_SERVICE_MANUAL,
    payload: data,
  }
}
